<template>
  <div class="w-full bg-white h-full">
    <div
      class="max-w-md mx-auto w-full space-y-12 h-full flex items-center justify-center flex-col"
    >
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue';
import { useRoute } from 'vue-router';
import moment from 'moment';

export default {
  name: "Invoices",
  setup() {
    const route = useRoute();
    const { id } = route.params;

    onMounted(() => {
      let url = 'https://d3k6t6l60lmqbi.cloudfront.net/invoices_zip/'+ id +'.zip';
      window.location.href = url;
    });

    return {
    };
  },
};
</script>